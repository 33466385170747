import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from 'styled-components';
import { HeaderThird } from 'components/header-third';

const SContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

const SHeaderThird = styled(HeaderThird)`
    margin: 1.75rem 0 1rem;
`;

const SIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6.125rem;
    height: 6.125rem;
    border-radius: 50%;
    background-color: var(--gray-color-90);
`;

export const FieldOfExpertise = ({ item: { icon, title, description } }) => {
    return (
        <SContainer>
            <SIconWrapper>{icon}</SIconWrapper>
            <SHeaderThird>
                <FormattedMessage id={title} />
            </SHeaderThird>
            <FormattedMessage id={description} />
        </SContainer>
    );
};

import React from 'react';
import BusinessIntelligenceIcon from 'svgs/icons/ic-chart.svg';
import CloudServicesIcon from 'svgs/icons/ic-cloud-add.svg';
import ArtificialIntelligenceIcon from 'svgs/icons/ic-cpu.svg';
import MachineLearningIcon from 'svgs/icons/ic-cpu-setting.svg';
import DataScienceIcon from 'svgs/icons/ic-data.svg';
import DataWarehousingIcon from 'svgs/icons/ic-driver.svg';

export const FIELDS_OF_EXPERIENCE_DATA = [
    {
        icon: <DataScienceIcon width={42} height={42} />,
        title: 'data-science-page.dataScience',
        description: 'data-science-page.dataScienceDescription',
    },
    {
        icon: <BusinessIntelligenceIcon width={42} height={42} />,
        title: 'data-science-page.businessIntelligence',
        description: 'data-science-page.businessIntelligenceDescription',
    },
    {
        icon: <ArtificialIntelligenceIcon width={42} height={42} />,
        title: 'data-science-page.artificialIntelligence',
        description: 'data-science-page.artificialIntelligenceDescription',
    },
    {
        icon: <MachineLearningIcon width={42} height={42} />,
        title: 'data-science-page.machineLearning',
        description: 'data-science-page.machineLearningDescription',
    },
    {
        icon: <DataWarehousingIcon width={42} height={42} />,
        title: 'data-science-page.dataWarehousing',
        description: 'data-science-page.dataWarehousingDescription',
    },
    {
        icon: <CloudServicesIcon width={42} height={42} />,
        title: 'data-science-page.cloudServices',
        description: 'data-science-page.cloudServicesDescription',
    },
];

import React from 'react';
import { METHODS_TABS } from 'views/data-science/constants';
import { TabSection } from 'modules/tab-section';

export const MethodsSection = () => {
    return (
        <TabSection
            tabs={METHODS_TABS}
            title="data-science-page.methodsOfGeneratingValueFromData"
            description="data-science-page.weUseTheR"
        />
    );
};

import React from 'react';
import { DATA_SCIENCE_PROCESS } from 'views/data-science/data-science-process-section/constants';
import { ProcessSection } from 'modules/process-section';

export const DataScienceProcessSection = () => (
    <ProcessSection
        title="data-science-page.theProcess"
        description="data-science-page.checkOurStrategic"
        data={DATA_SCIENCE_PROCESS}
    />
);
